<!--
  -- Created by zed on 2022/3/14
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 【智能设备添加页面】
-->
<template>
  <moe-page class="smart-equipment-add" title="智能设备添加">
    <moe-form ref="equipmentForm" label-width="240px" :model="equipment" :rules="equipmentRules">
      <el-form-item label="设备名称" prop="name">
        <el-input v-model.trim="equipment.name" placeholder="请输入设备名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="设备型号" prop="productModel">
        <el-input v-model.trim="equipment.productModel" oninput="value=value.replace(/，/g,',')" placeholder="请输入设备型号,多个请用,隔开" clearable />
      </el-form-item>
      <el-form-item label="产品ID" prop="productId">
        <el-input v-model.trim="equipment.productId" placeholder="请输入产品ID" clearable />
      </el-form-item>
      <el-form-item label="蓝牙广播名称" prop="bleName">
        <el-input v-model.trim="equipment.bleName" oninput="value=value.replace(/，/g,',')" placeholder="请输入蓝牙广播名称,多个请用,隔开" clearable />
      </el-form-item>
      <el-form-item label="控制模组" prop="module">
        <el-select v-model="equipment.module" placeholder="请选择控制模组" @change="equipment.fiveGhz = 0;">
          <el-option v-for="(item, key) in moduleList" :key="key" :label="key" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否支持5G频段WIFI" v-if="equipment.module && equipment.module != 1">
        <moe-radio-group v-model="equipment.fiveGhz" :radioBoxList="[{ label: '是', value: 1 }, { label: '否', value: 0 }]"></moe-radio-group>
      </el-form-item>
      <el-form-item label="设备类型" prop="type">
        <el-select v-model="equipment.type" placeholder="请选择设备类型">
          <el-option v-for="(item, key) in typeList" :key="key" :label="key" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通讯类型" prop="communication">
        <el-select v-model="equipment.communication" placeholder="请选择通讯类型">
          <el-option v-for="(item, key) in communicationList" :key="key" :label="key" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="无线类型" prop="wireless">
        <el-select v-model="equipment.wireless" placeholder="请选择无线类型">
          <el-option v-for="(item, key) in wirelessList" :key="key" :label="key" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否支持低功耗模式" prop="lowPower">
        <moe-radio-group v-model="equipment.lowPower" :radioBoxList="[{ label: '是', value: true }, { label: '否', value: false }]"></moe-radio-group>
      </el-form-item>
      <el-form-item label="PCD版刻">
        <el-input v-model.trim="equipment.pcd" placeholder="输入PCD版刻" clearable />
      </el-form-item>
      <el-form-item label="DP映射" prop="dpMappings">
        <el-input v-model.trim="equipment.dpMappings" placeholder="输入DP映射" clearable />
      </el-form-item>
      <el-form-item label="状态" prop="enable">
        <moe-radio-group v-model="equipment.enable" :radioBoxList="[{ label: '启用', value: true }, { label: '禁用', value: false }]"></moe-radio-group>
      </el-form-item>
      <el-form-item label="设备图片" prop="homeImage">
        <moe-upload-file
          v-model="equipment.homeImage"
          ossKey="DEVICE_MODEL"
          upload-text="上传图片"
          :size="20"
          @change="$refs.equipmentForm.validateField('homeImage')" />
      </el-form-item>
      <el-form-item label="通用图片" prop="image">
        <moe-upload-file
          v-model="equipment.image"
          ossKey="DEVICE_MODEL"
          upload-text="上传图片"
          :size="20"
          @change="$refs.equipmentForm.validateField('image')" />
      </el-form-item>
      <!-- <el-form-item label="说明文档" prop="files">
        <moe-upload-file
          v-model="equipment.files"
          ossKey="DEVICE_MODEL"
          upload-text="上传文档"
          @change="$refs.equipmentForm.validateField('files')" />
      </el-form-item> -->
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="equipmentLoad"
          @click="equipmentInfoSubmit">
          {{ equipmentLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'SmartEquipmentAdd',
  data() {
    const checkDpMappings = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        try {
          let isArray = JSON.parse(value);
          if (Array.isArray(isArray)) {
            callback();
          } else {
            callback(new Error(`数据格式不正确`));
          }
        } catch {
          callback(new Error(`数据格式不正确`));
        }
      }
    }
    //智能设备信息
    const equipment = {
      name: '',           // 设备名称
      productModel: '',   // 设备型号
      productId: '',      // 产品ID
      bleName: '',        // 蓝牙广播名称
      module: '',         // 控制模组
      type: '',           // 设备类型
      communication: '',  // 通讯类型
      wireless: '',       // 无线类型
      lowPower: false,    // 是否支持低功耗模式
      pcd: '',            // PCD版刻
      fiveGhz: 0,          // 是否支持5G频段WIFI
      dpMappings: '',     // DP映射
      enable: false,      // 是否启用 true false
      homeImage: '',      // 首页图片
      image: '',          // 通用图片
      // files: '',        // 说明文档
    }

    //智能设备数据校验
    const verify = this.$moe_verify.verifyForm
    const equipmentRules = {
      name: verify.isExtent(['请输入设备名称', '长度为 1 ~ 50'], '1~50'),
      productModel: verify.isEmpty('请输入设备型号,多个请用,隔开'),
      productId: verify.isEmpty('请输入产品ID'),
      bleName: verify.isEmpty('请输入蓝牙广播名称,多个请用,隔开'),
      module: verify.isEmpty('请选择控制模组'),
      type: verify.isEmpty('请选择设备类型'),
      communication: verify.isEmpty('请选择通讯类型'),
      wireless: verify.isEmpty('请选择无线类型'),
      lowPower: verify.isEmpty('请选择是否支持低功耗模式'),
      pcd: verify.isEmpty('请输入PCD版刻'),
      dpMappings: [{ required: true, validator: checkDpMappings, trigger: ['blur', 'change'] }],
      enable: verify.isEmpty('请选择状态'),
      homeImage: verify.isEmpty('请上传设备图片'),
      image: verify.isEmpty('请上传通用图片'),
    }

    return {
      equipment,            //智能设备信息
      equipmentRules,       //智能设备数据校验
      equipmentLoad: false, //提交状态
      moduleList: {},
      typeList: {},
      communicationList: {},
      wirelessList: {},
    }
  },
  methods: {
    /** 提交智能设备信息 */
    async equipmentInfoSubmit() {
      this.$refs['equipmentForm'].validate(() => {
        this.equipmentLoad = true;
        this.$moe_api.EQUIPMENT_API.smartEquipmentAdd(this.equipment).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('添加成功')
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message)
          }
        }).finally(() => {
          this.equipmentLoad = false
        })
      })
    },
  },
  mounted() {
    /** 可用参数 */
    this.$moe_api.EQUIPMENT_API.getDeviceModelParams().then((data) => {
      if (data.code === 200) {
        const { module, type, communication, wireless } = data.result
        this.moduleList = module;
        this.typeList = type;
        this.communicationList = communication;
        this.wirelessList = wireless;
      } else {
        this.$moe_msg.error(data.message)
      }
    })
  },
}
</script>